/* Variables */
:root {
  --form-max-width: 530px;
  --navbar-height: 58px;
}

/* Global */

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

p {
  margin-bottom: 0.5rem;
}

/* NavBar */

.navbar .nav-link.active {
  font-weight: 500;
}

@media (max-width: 575px) {
  .navbar .navbar-collapse {
    border-bottom: 1px solid var(--border-secondary);
    text-align: center;
    padding-bottom: 1.25rem;
  }
}

/* Icons */

svg[data-icon="telegram"] {
  color: var(--branding-color-telegram);
  height: 24px;
  width: 24px;
}

svg[data-icon="x-twitter"] {
  color: var(--branding-color-twitter);
  height: 24px;
  width: 24px;
}

svg[data-icon="instagram"] {
  color: var(--branding-color-instagram);
  height: 24px;
  width: 24px;
}

/* react zoom */

.react-transform-wrapper {
  height: 100vh !important;
  height: 100dvh !important;
  width: 100vw !important;
  width: 100dvw !important;
  justify-content: center;
  align-items: center;
}

.react-transform-component {
  height: 100vh !important;
  height: 100dvh !important;
  width: 100vw !important;
  width: 100dvw !important;
  justify-content: center;
  align-items: center;
}

/* Card */

.tiniest-card .card-body {
  display: none;
}

/* Image */

.square-wrapper {
  aspect-ratio: 1 / 1;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.image-element {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.image-element-all-border-radius {
  border-radius: 6px;
}

/* Modal */

.modal-overlay {
  position: fixed;
  background-color: var(--modal-overlay-button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-content {
  background-color: var(--modal-overlay-button-color);
  inset: 0;
  padding: 4px 32px 8px 32px;
  position: relative;
  min-width: 250px;
  flex: 0;
}

/* Misc / Utility */

.centered-column-grid {
  margin-top: var(--navbar-height);
  height: calc(100vh - var(--navbar-height));
  height: calc(100dvh - var(--navbar-height));
  overflow: auto;
  display: grid;
  justify-content: center;
  align-items: center;
}

.main-content-column {
  margin-top: var(--navbar-height);
  height: calc(100vh - var(--navbar-height));
  height: calc(100dvh - var(--navbar-height));
  overflow: auto;
}

.main-content-inner-wrapper {
  margin: 1.5rem auto;
}

.main-content-inner-wrapper.main-content-end-may-not-be-reached {
  height: calc(100vh - var(--navbar-height));
  height: calc(100dvh - var(--navbar-height));
}

.flex-spacer {
  flex: 1;
}

.border-radius-0-start {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-wrapper {
  max-width: var(--form-max-width);
  width: 100%;
  margin: 0 auto;
}

.remove-text-decoration {
  text-decoration: none !important;
}

.focus-style:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, .5);
}

.center-text {
  text-align: center;
}
