.admin-widget {
  position: fixed;
  top: var(--navbar-height);
  right: 0;
  display: flex;
  align-items: center;
  padding: 0.25em 0.75em;
  background-color: var(--admin-widget-bg-color);
  z-index: 1000000;
}

.admin-text {
  color: var(--admin-widget-text-color);
  font-size: 21px;
  font-weight: bold;
  margin-right: 0.5em;
}

.admin-icon {
  color: var(--admin-widget-text-color);
  font-size: 19px;
}
