.navbar {
  --bs-navbar-padding-y: 0;
  background-color: var(--bs-body-bg);
}

.nav-link-text {
  --bs-nav-link-font-size: 19px;
  margin-right: 0.15em;
}

.nav-link-icon {
  display: flex;
  justify-content: center;
  margin-left: 0.25em;
  min-height: 44px;
  min-width: 44px;
}

.nav-dropdown-icons {
  display: flex;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}

.nav-dropdown-icons .dropdown-item {
  padding: 2px 0.5rem;
}

.social-links {
  display: flex;
}

@media (max-width: 575px) {
  .social-links {
    margin-top: 0.75rem;
    margin-bottom: 0;
    justify-content: center;
  }
}

.nav-link-icon {
  padding-right: var(--bs-navbar-nav-link-padding-x) !important;
  padding-left: var(--bs-navbar-nav-link-padding-x) !important;
}

.dropdown {
  margin-right: 0.25rem;
  margin-left: 0.75rem;
}

.dropdown-toggle {
  padding: 5px 11px 3px 11px;
  margin-top: 3px;
  border-color: var(--nav-dropdown-toggle);
}

.dropdown-menu {
  padding-bottom: 0.75rem;
}

.dropdown-item {
  padding: 6px 1rem;
}

.dropdown-item-icon {
  padding: 6px 10px;
}
