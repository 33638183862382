:root {
  --admin-widget-bg-color: #faa;
  --admin-widget-text-color: #333;
  --border-default: #ccc;
  --border-secondary: #e7e7e7;
  --branding-color-instagram: #000;
  --branding-color-telegram: #229ed9;
  --branding-color-twitter: #000;
  --info-box-bg-color: #c3e7f5;
  --input-placeholder-icon-color: var(--bs-border-color);
  --modal-overlay-bg-color: rgba(0, 0, 0, 0.75);
  --modal-overlay-button-color: #fff;
  --nav-dropdown-toggle: #a7acb1;
  --text-secondary-color: #555;
  --view-selector-color: #aaa;
  --view-selector-color-active: #333;
}

@media (prefers-color-scheme: dark) {
  :root {
    --admin-widget-bg-color: #faa;
    --admin-widget-text-color: #333;
    --border-default: #dee2e6;
    --border-secondary: #595a5c;
    --bs-body-bg: #212529;
    --branding-color-instagram: #fff;
    --branding-color-telegram: #229ed9;
    --branding-color-twitter: #fff;
    --info-box-bg-color: #c3e7f5;
    --input-placeholder-icon-color: var(--bs-border-color);
    --modal-overlay-bg-color: rgba(0, 0, 0, 0.75);
    --modal-overlay-button-color: var(--bs-body-color);
    --nav-dropdown-toggle: #434547;
    --text-secondary-color: #b7b7b7;
    --view-selector-color: #595a5c;
    --view-selector-color-active: #bdbdbe;
  }
}
